exports.components = {
  "component---src-dynamic-pages-404-tsx": () => import("./../../../src/dynamic_pages/404.tsx" /* webpackChunkName: "component---src-dynamic-pages-404-tsx" */),
  "component---src-dynamic-pages-article-tsx": () => import("./../../../src/dynamic_pages/article.tsx" /* webpackChunkName: "component---src-dynamic-pages-article-tsx" */),
  "component---src-dynamic-pages-blog-tsx": () => import("./../../../src/dynamic_pages/blog.tsx" /* webpackChunkName: "component---src-dynamic-pages-blog-tsx" */),
  "component---src-dynamic-pages-category-tsx": () => import("./../../../src/dynamic_pages/category.tsx" /* webpackChunkName: "component---src-dynamic-pages-category-tsx" */),
  "component---src-dynamic-pages-contact-tsx": () => import("./../../../src/dynamic_pages/contact.tsx" /* webpackChunkName: "component---src-dynamic-pages-contact-tsx" */),
  "component---src-dynamic-pages-employer-branding-tsx": () => import("./../../../src/dynamic_pages/employer-branding.tsx" /* webpackChunkName: "component---src-dynamic-pages-employer-branding-tsx" */),
  "component---src-dynamic-pages-home-tsx": () => import("./../../../src/dynamic_pages/home.tsx" /* webpackChunkName: "component---src-dynamic-pages-home-tsx" */),
  "component---src-dynamic-pages-hr-outsourcing-tsx": () => import("./../../../src/dynamic_pages/hr-outsourcing.tsx" /* webpackChunkName: "component---src-dynamic-pages-hr-outsourcing-tsx" */),
  "component---src-dynamic-pages-job-tsx": () => import("./../../../src/dynamic_pages/job.tsx" /* webpackChunkName: "component---src-dynamic-pages-job-tsx" */),
  "component---src-dynamic-pages-jobs-tsx": () => import("./../../../src/dynamic_pages/jobs.tsx" /* webpackChunkName: "component---src-dynamic-pages-jobs-tsx" */),
  "component---src-dynamic-pages-privacy-policy-tsx": () => import("./../../../src/dynamic_pages/privacy-policy.tsx" /* webpackChunkName: "component---src-dynamic-pages-privacy-policy-tsx" */),
  "component---src-dynamic-pages-recruitment-tsx": () => import("./../../../src/dynamic_pages/recruitment.tsx" /* webpackChunkName: "component---src-dynamic-pages-recruitment-tsx" */),
  "component---src-dynamic-pages-technical-evaluation-tsx": () => import("./../../../src/dynamic_pages/technical-evaluation.tsx" /* webpackChunkName: "component---src-dynamic-pages-technical-evaluation-tsx" */),
  "component---src-dynamic-pages-training-tsx": () => import("./../../../src/dynamic_pages/training.tsx" /* webpackChunkName: "component---src-dynamic-pages-training-tsx" */),
  "component---src-dynamic-pages-trainings-tsx": () => import("./../../../src/dynamic_pages/trainings.tsx" /* webpackChunkName: "component---src-dynamic-pages-trainings-tsx" */),
  "component---src-i-18-n-redirect-tsx": () => import("./../../../src/i18n/redirect.tsx" /* webpackChunkName: "component---src-i-18-n-redirect-tsx" */)
}

