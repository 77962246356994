module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Omni Skills","short_name":"Omni Skills","description":"Diverse Talents, Unified Success","start_url":"/pl/","background_color":"#4044FE","theme_color":"#4044FE","display":"standalone","icon":"src/icons/favicon.png","crossOrigin":"use-credentials","lang":"pl","localize":[{"start_url":"/en/","lang":"en","name":"Omni Skills","short_name":"Omni Skills","description":"Diverse Talents, Unified Success"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"37a55badbfa6dd1d7d15bee42f37b458"},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-867PC068P1"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
